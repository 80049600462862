import { apiRemote } from '../../Services/RemoteService';

class UsersAPI {
  static getCompanyUsers() {
    return apiRemote
      .get(`admin/users`)
      .then((response) => response.json())
      .catch((e) => e);
  }

  static getCurrentUser() {
    return apiRemote
      .get(`admin/users/user`)
      .then((response) => response.json())
      .catch((e) => e);
  }

  static isUsernameAvailable(username) {
    return apiRemote
      .get(`admin/users/username?username=${username}`)
      .then((response) => response.json());
  }

  static createGroupUser({
    first_name,
    last_name,
    email,
    phone_number,
    password,
    username,
    timezone,
    is_hub_account,
    integrations,
    currentLeasingTeamIds,
  }) {
    const payload = {
      leasing_team_id: currentLeasingTeamIds,
      first_name,
      last_name,
      email,
      phone_number,
      username,
      password,
      timezone,
      is_hub_account,
      property_integration_mappings: integrations.map((integration) => {
        return {
          integration_agent_id: integration.mapped_agent_id,
          property_id: integration.property_id,
          vendor_property_id: integration.vendor_property_id,
        };
      }),
      role: 'agent',
    };

    return apiRemote.post(`admin/users`, payload);
  }

  static updateGroupUser(
    userId,
    {
      is_hub_account,
      first_name,
      last_name,
      email,
      phone_number,
      username,
      timezone,
      currentLeasingTeamIds,
    },
    mappings
  ) {
    const payload = {
      is_hub_account,
      first_name,
      last_name,
      email,
      phone_number,
      username,
      timezone,
      property_integration_mappings: mappings.map((mapping) => {
        return {
          integration_agent_id: mapping.mapped_agent_id,
          property_id: mapping.property_id,
          vendor_property_id: mapping.vendor_property_id,
        };
      }),
    };

    if (currentLeasingTeamIds) {
      payload['leasing_team_ids'] = currentLeasingTeamIds;
    }

    return apiRemote.put(`admin/users/${userId}`, payload);
  }

  static addGroup(name) {
    return apiRemote
      .post(`admin/users/teams`, { name })
      .then((response) => response.json());
  }

  static updateGroup(id, name) {
    return apiRemote
      .put(`admin/users/teams/${id}`, { name })
      .then((response) => response.json());
  }

  static deleteUser(userId, transferToUserId) {
    return apiRemote.delete(`admin/users/${userId}`, {
      transfer_to: transferToUserId,
    });
  }

  static updateUserPassword(userId, newPassword) {
    return apiRemote.put(`admin/users/${userId}/password`, {
      password: newPassword,
    });
  }

  static importTeams(importFormData) {
    return apiRemote
      .postFile(`admin/imports/leasing-teams`, importFormData)
      .then((response) => response.json());
  }

  static async transferTeam(leasingTeamId, transferTeamMasterUsername) {
    const payload = {
      master_username: transferTeamMasterUsername,
    };

    const response = await apiRemote.post(
      `admin/users/teams/${leasingTeamId}/transfer`,
      payload
    );

    if (response.status !== 201 && response.status !== 400) {
      throw new Error();
    }

    const responseBody = await response.json();

    if (response.status === 400) {
      const errorMessage = responseBody.error;
      throw new Error(errorMessage);
    }

    return responseBody;
  }

  static importUsers(importFormData) {
    return apiRemote
      .postFile(`admin/imports/users`, importFormData)
      .then((response) => response.json());
  }

  static getCognitoUserLeasingTeams(userId) {
    return apiRemote
      .get(`admin/users/${userId}/teams`)
      .then((response) => response.json())
      .catch((e) => e);
  }

  static getLeasingTeamsList() {
    return apiRemote
      .get('users/teams')
      .then((response) => response.json())
      .catch((e) => e);
  }
}

export default UsersAPI;
