import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Text,
  makeStyles,
} from '@knockrentals/knock-shared-web';

const useStyles = makeStyles(() => ({
  dialogActions: {
    padding: '16px',
  },
  mBottom20: {
    marginBottom: '20px',
  },
}));

const SaveBulkEditModal = ({ title, propertyLength, isOpen, handleClose, handleSave }) => {
  const classes = useStyles();
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle disableTypography={true}>
        <Text variant="h6" className={classes.mBottom20}>
          {title}
        </Text>
        <Text variant="body1">
          Are you sure you want to update the following {propertyLength} selected properties?
        </Text>
      </DialogTitle>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleSave();
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SaveBulkEditModal;