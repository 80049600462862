import React from 'react';
import PropTypes from 'prop-types';

import {
  CardContent,
  Divider,
  Text,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import CompanyTemplateCard from '../CompanyTemplate/CompanyTemplateCard';
import { CompanyTemplateActions } from '../CompanyTemplate/CompanyTemplateActions';
import { useFeatureFlagContext } from '../../../../Context/FeatureFlagContext';
import { TEMPLATE_TYPES, TEMPLATE_TYPE_TITLES } from '../constants';
import './_CompanyTemplatesTooltip.scss';

export const ARIA_LABEL = 'tooltip button';
export const DRAWER_SUBHEADER = 'Company Templates Info';

export const TEMPLATES = {
  // TODO - add description for TEMPLATES when template tooltips are enabled
  description: 'templates description placeholder',
  templateTypes: [
    TEMPLATE_TYPES.SELF_GUIDED_TOUR_INSTRUCTIONS_EMAIL,
    TEMPLATE_TYPES.SELF_GUIDED_TOUR_INSTRUCTIONS_SMS,
  ],
  title: 'Templates',
};

export const AUTO_RESPONDERS = {
  description:
    "An autoresponder is software that automatically sends pre-written emails or messages to a subscriber's email address or phone number in response to specific triggers. These triggers can include inquiries or questions sent to your property from prospects. Keep in mind that autoresponders should not be used for time-sensitive or urgent messages, and they should not replace personal communication with customers.",
  templateTypes: [
    TEMPLATE_TYPES.AUTORESPONSE_EMAIL,
    TEMPLATE_TYPES.AUTORESPONSE_SMS,
    TEMPLATE_TYPES.SELF_GUIDED_TOUR_EMAIL,
    TEMPLATE_TYPES.SELF_GUIDED_TOUR_SMS,
    TEMPLATE_TYPES.WIN_BACK_EMAIL,
    TEMPLATE_TYPES.LIVE_VIDEO_TOUR_INSTRUCTIONS,
  ],
  title: 'Auto-Responders',
};

export const TOOLTIPS = {
  [TEMPLATE_TYPES.AUTORESPONSE_EMAIL]:
    'An email autoresponder is a feature that automates email replies triggered when someone sends your property an email. This is usually triggered as responses to another email that has been sent.',
  [TEMPLATE_TYPES.AUTORESPONSE_SMS]:
    'An SMS autoresponder is a tool that helps you set up automated responses for incoming text messages. SMS messages can only be sent using plain text and cannot exceed 140 characters. The autoresponse can include a custom message for your property or include a default message based on your set-up.',
  [TEMPLATE_TYPES.LIVE_VIDEO_TOUR_INSTRUCTIONS]:
    'Include a message to your prospects with instructions for the live tour. The tour URL is entered in the <???>',

  // TODO - add tooltip for SELF_GUIDED_TOUR_EMAIL when enabled
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_EMAIL]:
    'Post-Visit automated email messages are sent to prospects after they have visited your property. This typically includes relevant information to re-engage with them after they have visited your property. The autoresponse can include a custom message for your property or include a default message based on your set-up.',

  // TODO - add tooltip for SELF_GUIDED_TOUR_SMS when enabled
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_SMS]:
    'Post-Visit automated SMS messages are sent to prospects after they have visited your property. This typically includes relevant information to re-engage with them after they have visited your property. The autoresponse can include a custom message for your property or include a default message based on your set-up.',
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_INSTRUCTIONS_EMAIL]:
    'These are the instructions automatically sent with the appointment confirmation email. You can include key codes, where to get the key, what unit they will be touring. Add any information you need the prospect to know before the tour so they are set up for a great experience!',
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_INSTRUCTIONS_SMS]:
    'These are the instructions automatically sent with the appointment confirmation sms. You can include key codes, where to get the key, what unit they will be touring. Add any information you need the prospect to know before the tour so they are set up for a great experience!',
  [TEMPLATE_TYPES.WIN_BACK_EMAIL]:
    'A win-back autoresponse email is feature that automates emails to prospects, applications or residents encouraging those recipients to re-engage with the property. The autoresponse can include a custom message for your property or include a default message based on your set-up.',
};

const TOOLTIP_SECTIONS = [TEMPLATES, AUTO_RESPONDERS];

const useStyles = makeStyles({
  tooltipCardContent: {
    flexGrow: 1,
    background: '#F7F7F8',
    overflowY: 'auto',
  },
});

const CompanyTemplatesInfo = ({ closeDrawer }) => {
  const classes = useStyles();

  const {
    isCompanyTemplatesSelfGuidedToursEnabled,
    isCompanyTemplatesWinbackEnabled,
  } = useFeatureFlagContext();

  const isToolTipEnabled = {
    [TEMPLATE_TYPES.AUTORESPONSE_EMAIL]: true,
    [TEMPLATE_TYPES.AUTORESPONSE_SMS]: true,
    [TEMPLATE_TYPES.LIVE_VIDEO_TOUR_INSTRUCTIONS]: false,
    [TEMPLATE_TYPES.SELF_GUIDED_TOUR_EMAIL]:
      isCompanyTemplatesSelfGuidedToursEnabled,
    [TEMPLATE_TYPES.SELF_GUIDED_TOUR_SMS]:
      isCompanyTemplatesSelfGuidedToursEnabled,
    [TEMPLATE_TYPES.SELF_GUIDED_TOUR_INSTRUCTIONS_EMAIL]: false,
    [TEMPLATE_TYPES.SELF_GUIDED_TOUR_INSTRUCTIONS_SMS]: false,
    [TEMPLATE_TYPES.WIN_BACK_EMAIL]: isCompanyTemplatesWinbackEnabled,
  };

  return (
    <CompanyTemplateCard closeDrawer={closeDrawer} subheader={DRAWER_SUBHEADER}>
      <CardContent className={classes.tooltipCardContent}>
        {TOOLTIP_SECTIONS.map((section, index) => {
          const enabledTemplateTypes = section.templateTypes.filter(
            (templateType) => isToolTipEnabled[templateType]
          );

          if (!enabledTemplateTypes.length) {
            return null;
          }

          return (
            <div className="tooltip-section" key={section.title}>
              <Text gutterBottom variant="h6">
                {section.title}
              </Text>
              <Text paragraph>{section.description}</Text>

              <div className="template-type-tooltip">
                {enabledTemplateTypes.map((templateType) => {
                  return (
                    <div key={templateType}>
                      <Text gutterBottom variant="subtitle1">
                        {TEMPLATE_TYPE_TITLES[templateType]}
                      </Text>

                      <Text paragraph variant="body2">
                        {TOOLTIPS[templateType]}
                      </Text>
                    </div>
                  );
                })}
              </div>
              {index === 0 && <Divider />}
            </div>
          );
        })}
      </CardContent>

      <CompanyTemplateActions
        isCancelButtonHidden
        handleSaveOnClick={closeDrawer}
        primaryText="Done"
      />
    </CompanyTemplateCard>
  );
};

CompanyTemplatesInfo.propTypes = {
  closeDrawer: PropTypes.func,
};

export default CompanyTemplatesInfo;
