import React from 'react';
import PropTypes from 'prop-types';

import { List } from '@knockrentals/knock-shared-web';
import { CompanyTemplateTypeAccordion } from './CompanyTemplateTypeAccordion';
import { CompanyTemplates } from './CompanyTemplates';
import AddIconButton from '../../common/AddIconButton/AddIconButton';
import { useFeatureFlagContext } from '../../../../Context/FeatureFlagContext';

import { TEMPLATE_TYPES, TEMPLATE_TYPE_TITLES } from '../constants';
import './_CompanyTemplatesList.scss';

export const TEMPLATE_ORDER = [
  TEMPLATE_TYPES.AUTORESPONSE_EMAIL,
  TEMPLATE_TYPES.AUTORESPONSE_SMS,
  TEMPLATE_TYPES.SELF_GUIDED_TOUR_EMAIL,
  TEMPLATE_TYPES.SELF_GUIDED_TOUR_SMS,
  TEMPLATE_TYPES.WIN_BACK_EMAIL,
];

const CompanyTemplatesList = ({
  setCompanyTemplate,
  templatesByType,
  updateIsEnabled,
}) => {
  const {
    isCompanyTemplatesSelfGuidedToursEnabled,
    isCompanyTemplatesWinbackEnabled,
  } = useFeatureFlagContext();

  const shouldRenderTemplateType = (templateType) => {
    switch (templateType) {
      case TEMPLATE_TYPES.WIN_BACK_EMAIL:
        return isCompanyTemplatesWinbackEnabled;

      case TEMPLATE_TYPES.SELF_GUIDED_TOUR_EMAIL:
      case TEMPLATE_TYPES.SELF_GUIDED_TOUR_SMS:
        return isCompanyTemplatesSelfGuidedToursEnabled;

      default:
        return true;
    }
  };

  return (
    <section className="company-template-list">
      <List>
        {TEMPLATE_ORDER.map((templateType) => {
          if (!shouldRenderTemplateType(templateType)) {
            return null;
          }

          const {
            propertyCount = 0,
            templates = [],
            templateCount = 0,
          } = templatesByType[templateType] || {};

          return (
            <CompanyTemplateTypeAccordion
              key={templateType}
              propertyCount={propertyCount}
              templateCount={templateCount}
              title={TEMPLATE_TYPE_TITLES[templateType]}
            >
              <CompanyTemplates
                propertyCount={propertyCount}
                setCompanyTemplate={setCompanyTemplate}
                templates={templates}
                templateType={templateType}
                updateIsEnabled={updateIsEnabled}
              />

              <AddIconButton
                handleOnClick={() =>
                  setCompanyTemplate({ propertyCount, templateType })
                }
                primaryText="Create New Template"
              />
            </CompanyTemplateTypeAccordion>
          );
        })}
      </List>
    </section>
  );
};

CompanyTemplatesList.propTypes = {
  setCompanyTemplate: PropTypes.func,
  templatesByType: PropTypes.objectOf(
    PropTypes.shape({
      propertyCount: PropTypes.number,
      templateCount: PropTypes.number,
      templates: PropTypes.arrayOf(
        PropTypes.shape({
          isEnabled: PropTypes.bool,
          assignedPropertiesCount: PropTypes.number,
          templateId: PropTypes.number,
          templateName: PropTypes.string,
        })
      ),
    })
  ),
  updateIsEnabled: PropTypes.func,
};

export default CompanyTemplatesList;
