import {
  BASE_API_URI,
  BASE_ANALYTICS_API_URI,
  BASE_AI_URI,
  BASE_ML_URI,
  BASE_PHONE_REGISTRATION_URI,
} from '../config';
import { RestHttp } from '@knockrentals/knock-react';
import LoginAPI from '../Pages/Login/LoginAPI';
import { AuthenticationService } from '@knockrentals/knock-react';

let refreshingToken = false;
let afterAuthPromise = null;

function authPromise() {
  return Promise.resolve().then(() => {
    // check if there is a token before running
    const token = AuthenticationService.getToken();
    if (token) {
      const tokenExpDate = new Date(
        AuthenticationService.getTokenPayload().exp
      ).getTime();
      let currentDate = new Date().getTime();
      // check if token is expired
      if (tokenExpDate < currentDate) {
        if (refreshingToken) {
          // if refreshing auth is currently running, return promise
          return afterAuthPromise;
        } else {
          // run our auth method and block other calls from processing
          refreshingToken = true;
          var refreshToken = AuthenticationService.getRefreshToken();
          afterAuthPromise = LoginAPI.refreshAuthToken(refreshToken)
            .then((response) => {
              // on success set new token and resolve
              AuthenticationService.setToken(response.access_token);
              refreshingToken = false;
            })
            .catch(() => {
              // if this fails, nuke everything and reload
              AuthenticationService.clearToken();
              window.location.reload();
            });
          return afterAuthPromise;
        }
      }
    }
  });
}

RestHttp.prototype.patch = function (path, body) {
  return fetch(this.baseUri + path, {
    headers: this.getHeaders(),
    method: 'PATCH',
    body: JSON.stringify(body),
  });
};

// this function refactors the methods listed in refactoredMethods array below
['get', 'patch', 'put', 'post', 'delete', 'postFile'].forEach((name) => {
  const old = RestHttp.prototype[name];
  // make sure we dont run our auth method when refreshing auth token
  if (name === 'post') {
    RestHttp.prototype[name] = async function (...args) {
      if (arguments[0].includes('auth/refresh')) {
        return old.call(this, ...args);
      }
      await authPromise();
      return old.call(this, ...args);
    };
  } else {
    RestHttp.prototype[name] = async function (...args) {
      await authPromise();
      const response = await old.call(this, ...args);
      if (response.status === 401 || response.status === 403) {
        // Nuke everything and bring us back to square one if our tokens get messed up
        localStorage.clear();
        window.location.reload();
      }
      return response;
    };
  }
});

const responseHandler = async (response) => {
  const { status } = response;

  if (status < 200 || status >= 400) {
    throw new Error(`Request returned error status code ${status}`);
  }

  const responseJson = await response.json();

  // Throw if the API returns a 200 status but still contains an error:
  if (responseJson.errorMessage) {
    throw new Error(responseJson.errorMessage);
  }

  Object.keys(responseJson).forEach((key) => {
    // Only look for an error message property if there is a value for the given key
    if (responseJson[key]) {
      const { errorMessage } = responseJson[key];

      if (errorMessage) {
        throw new Error(errorMessage);
      }
    }
  });

  return responseJson;
};

const apiRemote = new RestHttp(BASE_API_URI);
const analyticsApiRemote = new RestHttp(BASE_ANALYTICS_API_URI);
const aiRemote = new RestHttp(BASE_AI_URI);
const mlRemote = new RestHttp(BASE_ML_URI);
const phoneRegistrationRemote = new RestHttp(BASE_PHONE_REGISTRATION_URI);

export {
  apiRemote,
  analyticsApiRemote,
  aiRemote,
  mlRemote,
  phoneRegistrationRemote,
  responseHandler,
};
