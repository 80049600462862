import React, { Component } from 'react';
import moment from 'moment';
import GroupUserEditor from './GroupUserEditor';
import IntegrationAPI from '../Integrations/IntegrationsAPI';
import UsersAPI from './UsersAPI';
import TeamEditor from './TeamEditor';
import TransferTeam, { getTransferTeamErrorMessage } from './TransferTeam';
import LoginAPI from '../Login/LoginAPI';
import { AuthenticationService, Toaster } from '@knockrentals/knock-react';
import HoursAPI from '../Hours/HoursAPI';
import DeleteGroupUserSelect from './DeleteGroupUserSelect';
import DeleteGroupUserConfirm from './DeleteGroupUserConfirm';
import { IntegrationVendorNames } from '../Integrations/constants';
import * as _ from 'lodash';
import { FeatureFlagContext } from '../../Context/FeatureFlagContext';
import { Tooltip } from '@knockrentals/knock-shared-web';
import { UNIFIED_LOGIN_URL } from '../../config';

class GroupUsers extends Component {
  static VendorMapping = {
    realpage: IntegrationVendorNames.REALPAGE,
    resman: IntegrationVendorNames.RESMAN,
    yardi: IntegrationVendorNames.YARDI,
    entrata: IntegrationVendorNames.ENTRATA,
  };

  state = {
    selectedUser: null,
    isAddingUser: false,
    editingTeam: false,
    deletingUserId: null,
    integrationsSynchronizing: {},
    transferToUserId: null,
    transferringTeam: null,
    transferringTeamErrors: null,
    transferringTeamInProgress: false,
    loggedInUserType: AuthenticationService.getRole(),
    selectingTransfer: false,
    confirmDeleteGroupUser: false,
    isSaving: false,
    deletionsInProgress: JSON.parse(
      sessionStorage.getItem('deletionsInProgress')
    ),
  };

  _isMounted = false;

  static contextType = FeatureFlagContext;

  async componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  useCookieForToken = () => {
    const { isUseCookieForTokenEnabled } = this.context;
    return isUseCookieForTokenEnabled;
  };

  userDeleteDisabled = () => {
    const isInternalMode = AuthenticationService._internalMode;
    return isInternalMode ? false : this.context.isUserDeleteDisabled;
  };

  submitTransferTeam(transferMasterUsername) {
    this.setState({ transferringTeamInProgress: true });

    const thisLeasingTeamId = this.props.group.leasing_team_id;

    return UsersAPI.transferTeam(thisLeasingTeamId, transferMasterUsername)
      .then((response) => {
        this.setState({ transferringTeamErrors: response.error });

        Toaster.showToast('Transferred!', 2000, Toaster.ToastClasses.success);
      })
      .catch((error) => {
        const errorMessage = getTransferTeamErrorMessage(error.message);
        Toaster.showToast(errorMessage, 2000, Toaster.ToastClasses.error);
      })
      .finally(() => {
        this.setState({
          transferringTeam: false,
          transferringTeamInProgress: false,
        });
      });
  }

  render() {
    return (
      <div>
        <h2>
          {this.props.group.leasing_team_name}{' '}
          {`(ID: ${this.props.group.leasing_team_public_id})`}
          {AuthenticationService._internalMode ? (
            <button
              className="knock-react-button"
              onClick={() => {
                this.setState({ editingTeam: true });
              }}
            >
              Edit team
            </button>
          ) : null}
          {AuthenticationService._internalMode ? (
            <button
              className="knock-react-button"
              onClick={() => {
                this.setState({ transferringTeam: true });
              }}
            >
              Transfer team
            </button>
          ) : null}
        </h2>
        {this.renderTable()}

        {!this.state.isAddingUser ? (
          <button
            className="knock-react-button"
            onClick={this.addUser.bind(this)}
          >
            Add new user
          </button>
        ) : null}

        {this.state.editingTeam ? (
          <TeamEditor
            team={this.props.group}
            onUpdateTeam={this.onUpdateTeam.bind(this)}
            onCancel={() => {
              this.setState({ editingTeam: false });
            }}
          />
        ) : null}

        {(this.state.selectedUser || this.state.isAddingUser) && (
          <GroupUserEditor
            groups={this.props.groups}
            group={this.props.group}
            isSaving={this.state.isSaving}
            isUserManagementEnabled={this.props.isUserManagementEnabled}
            onCancel={this.onCancelEdit.bind(this)}
            onUserCreated={this.onUserCreated.bind(this)}
            onUserPasswordUpdated={this.onUserPasswordUpdated.bind(this)}
            onUserUpdated={this.onUserUpdated.bind(this)}
            renderIntegrationAgents={this.renderIntegrationAgents.bind(this)}
            setIsUserManagementEnabled={this.props.setIsUserManagementEnabled}
            user={this.state.selectedUser}
            vendorMapping={GroupUsers.VendorMapping}
          />
        )}

        {this.state.transferringTeam ? (
          <TransferTeam
            team={this.props.group}
            onSubmit={this.submitTransferTeam.bind(this)}
            onCancel={() => {
              this.setState({ transferringTeam: false });
            }}
            transferErrors={this.state.transferringTeamErrors}
          />
        ) : null}

        {this.state.deletingUserId && this.state.selectingTransfer && (
          <DeleteGroupUserSelect
            deletingUserId={this.state.deletingUserId}
            deletionsInProgress={this.state.deletionsInProgress || []}
            transferToUserId={this.state.transferToUserId}
            users={this.props.group.users}
            onTransferToUserChanged={this.transferToUserIdChanged}
            onCancel={this.cancelDeletingUser}
            onConfirm={this.confirmDelete}
          />
        )}

        {this.state.deletingUserId && this.state.confirmDeleteGroupUser && (
          <DeleteGroupUserConfirm
            deletingUserId={this.state.deletingUserId}
            transferToUserId={this.state.transferToUserId}
            users={this.props.group.users}
            onCancel={this.cancelDeletingUser}
            onBack={this.backToSelect}
            onDelete={this.deleteUser}
          />
        )}
      </div>
    );
  }

  renderTable() {
    if (this.props.group.users.length === 0) {
      return (
        <div>
          <span>There are no users for this leasing team.</span>
        </div>
      );
    }

    return (
      <table className="wire-frame-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Username</th>
            <th>Contact Email</th>
            <th>Phone</th>
            <th>Timezone</th>
            {this.renderIntegrationHeaders()}
            <th />
            <th>House?</th>
            <th>Last Login On</th>
            <th>User Migrated to RealPage Unified Platform</th>
          </tr>
        </thead>
        <tbody>{this.renderUsers()}</tbody>
      </table>
    );
  }

  synchronizeIntegration(integration) {
    const integrationsSynchronizing = this.state.integrationsSynchronizing;

    integrationsSynchronizing[integration.vendor_property_id] = true;

    this.setState({ integrationsSynchronizing });

    return IntegrationAPI.synchronizeIntegration(
      integration.property_id,
      integration.vendor_property_id,
      integration.vendor
    )
      .then(() => {
        window.location.reload();
      })
      .finally(() => {
        const integrationsSynchronizing = this.state.integrationsSynchronizing;

        integrationsSynchronizing[integration.vendor_property_id] = false;

        this.setState({ integrationsSynchronizing });
      });
  }

  renderIntegrationHeaders() {
    return this.props.group.integrations
      .filter((integration) => integration !== null)
      .map((integration) => {
        const integrationKey =
          integration.vendor +
          '-' +
          integration.property_id +
          '-' +
          integration.vendor_property_id;

        const isSynchronizing =
          this.state.integrationsSynchronizing[integration.vendor_property_id];
        const clickableClass = isSynchronizing
          ? 'fa fa-refresh fa-fw fa-spin'
          : 'fa fa-refresh fa-fw';

        const integrationSyncClickable = (
          <a
            href="#"
            onClick={() => {
              this.synchronizeIntegration(integration);
            }}
          >
            <i className={clickableClass} />
          </a>
        );
        const integrationLabel = (
          <span>
            {' ( ID: ' + integration.vendor_property_id + ') '}{' '}
            {integrationSyncClickable}{' '}
          </span>
        );

        return (
          <th key={integrationKey}>
            {GroupUsers.VendorMapping[integration.vendor]} Mapping{' '}
            {integrationLabel}{' '}
          </th>
        );
      });
  }

  isDeleteButtonDisabled(deletionInProgress, user, group) {
    return (
      deletionInProgress ||
      user.is_hub_account ||
      group.owner_manager_ids.includes(user.user_id) ||
      user.is_migrated
    );
  }

  getDeleteButtonTooltip(user, group) {
    let tooltip = '';

    if (user.is_hub_account) {
      tooltip =
        'This user must be unmarked as the house account before removal';
    }

    if (group.owner_manager_ids.includes(user.user_id)) {
      tooltip =
        "This user is the owning manager for a property and can't be removed";
    }

    if (user.is_migrated) {
      tooltip =
        'This user has been migrated. Delete button for this user has been disabled. Click here to modify user in Unified Platform';
    }

    return tooltip;
  }

  renderUsers() {
    return this.props.group.users.map((user) => {
      const editUser = () => {
        this.editUser(user);
      };

      const loginToUser = () => {
        this.loginToUser(user);
      };

      const otherUsers = this.props.group.users.filter(
        (otherUser) => otherUser.user_id !== user.user_id
      );

      const deletionInProgress =
        this.state.deletionsInProgress &&
        Array.isArray(this.state.deletionsInProgress)
          ? this.state.deletionsInProgress.includes(user.user_id)
          : false;

      const deleteButtonDisabled = this.isDeleteButtonDisabled(
        deletionInProgress,
        user,
        this.props.group
      );

      return (
        <tr key={user.user_id}>
          <td>
            {user.first_name} {user.last_name}
          </td>
          <td>
            {user.cognito_username ? (
              <span>
                {user.cognito_username} <small>(cognito)</small>
                <br />
              </span>
            ) : null}
            {user.username ? user.username : null}
          </td>
          <td>{user.email}</td>
          <td>{user.phone_number}</td>
          <td>{this.renderTimezoneDisplayName(user)}</td>
          {this.renderUserIntegrations(user)}
          <td>
            {this.state.deletingUserId !== user.user_id && (
              <div>
                <button className="knock-react-button" onClick={editUser}>
                  Edit
                </button>
                {user.is_migrated && (
                  <Tooltip title="This user has been migrated. Certain properties for this user cannot be edited. Click here to modify user in Unified Platform">
                    <a
                      href={UNIFIED_LOGIN_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-regular fa-question-circle edit-button-tooltip"></i>
                    </a>
                  </Tooltip>
                )}
                <button className="knock-react-button" onClick={loginToUser}>
                  Login
                </button>
                {this.props.group.users.length > 1 &&
                  !this.userDeleteDisabled() && (
                    <React.Fragment>
                      <button
                        className="knock-react-button danger"
                        disabled={deleteButtonDisabled}
                        onClick={this.startDeletingUser.bind(
                          this,
                          user.user_id,
                          otherUsers[0].user_id
                        )}
                      >
                        {deletionInProgress ? (
                          <i
                            className="fa fa-spinner fa-spin"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <span>Delete</span>
                        )}
                      </button>

                      {deleteButtonDisabled && (
                        <Tooltip
                          title={this.getDeleteButtonTooltip(
                            user,
                            this.props.group
                          )}
                        >
                          {user.is_migrated ? (
                            <a
                              href={UNIFIED_LOGIN_URL}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa-regular fa-question-circle delete-button-tooltip"></i>
                            </a>
                          ) : (
                            <i className="fa-regular fa-question-circle delete-button-tooltip"></i>
                          )}
                        </Tooltip>
                      )}
                    </React.Fragment>
                  )}
              </div>
            )}
          </td>
          <td>
            <input
              type="checkbox"
              checked={user.is_hub_account}
              onChange={this.isHubChanged.bind(this, user)}
            />
          </td>
          <td>
            {user.last_login ? this.renderLastLogin(user.last_login) : null}
          </td>
          <td>{user.is_migrated ? 'migrated' : ''}</td>
        </tr>
      );
    });
  }

  renderLastLogin(lastLogin) {
    return moment(lastLogin).format('L [at] h:mm a');
  }

  isHubChanged(user, event) {
    user.is_hub_account = event.target.checked;

    this.onUserUpdated({
      ...user,
      username: user.cognito_username || user.username,
    });
  }

  /* renderUserTimezones is deprecated due to issues
    editing timezones for multi-team users via the dropdown;
    will be removed in the future once we're certain we won't need to re-enable it
   */
  renderUserTimezones(user) {
    const userTimezoneChanged = (event) => {
      user.timezone = event.target.value;
      user.currentLeasingTeamIds = [this.props.group.leasing_team_id];
      UsersAPI.updateGroupUser(user.user_id, user, user.integrations)
        .then(() => {
          Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
        })
        .catch(() => {
          Toaster.showToast(
            'Error saving timezone',
            2000,
            Toaster.ToastClasses.error
          );
        });

      this.setState({ value: user.timezone });
    };

    return (
      <td>
        <select value={user.timezone || null} onChange={userTimezoneChanged}>
          {user.timezone === null ? (
            <option value={null}>--Select--</option>
          ) : null}
          {GroupUsers.renderTimezoneOptions()}
        </select>
      </td>
    );
  }

  /* renderTimezoneOptions is also deprecated due to issues
    editing timezones for multi-team users
   */
  static renderTimezoneOptions() {
    return HoursAPI.Timezones.map((timezone) => (
      <option key={timezone.id} value={timezone.id}>
        {timezone.name}
      </option>
    ));
  }

  renderTimezoneDisplayName(user) {
    const displayTimezone = HoursAPI.Timezones.find(
      (tz) => tz.id === user.timezone
    );
    if (displayTimezone && displayTimezone.name) return displayTimezone.name;
    return 'None Selected';
  }

  renderUserIntegrations(user) {
    if (user.property_integration_mappings) {
      user.integrations = user.property_integration_mappings;
    }

    if (!user.integrations) {
      return null;
    }

    return this.props.group.integrations.map((integration) => {
      const userMappingChanged = (event) => {
        let updatedIntegration = user.integrations.find(
          (i) =>
            i.vendor === integration.vendor &&
            i.property_id === integration.property_id &&
            i.vendor_property_id === integration.vendor_property_id
        );

        if (!updatedIntegration) {
          user.integrations = this.props.group.integrations.map((i) => {
            const existingIntegration = user.integrations.find(
              (userIntegration) =>
                i.vendor === userIntegration.vendor &&
                i.property_id === userIntegration.property_id &&
                i.vendor_property_id === userIntegration.vendor_property_id
            );

            if (existingIntegration) {
              return existingIntegration;
            }

            return {
              property_id: i.property_id,
              mapped_agent_id: null,
              vendor: i.vendor,
              vendor_property_id: i.vendor_property_id,
            };
          });

          updatedIntegration = user.integrations.find(
            (i) =>
              i.vendor === integration.vendor &&
              i.property_id === integration.property_id &&
              i.vendor_property_id === integration.vendor_property_id
          );
        }

        updatedIntegration.mapped_agent_id = event.target.value;

        this.onUserUpdated(user);
      };

      const matchingIntegration = user.integrations.find(
        (i) =>
          i.vendor === integration.vendor &&
          i.property_id === integration.property_id &&
          i.vendor_property_id === integration.vendor_property_id
      );

      const integrationKey =
        integration.vendor +
        '-' +
        integration.property_id +
        '-' +
        integration.vendor_property_id;

      return (
        <td key={integrationKey}>
          <select
            value={
              matchingIntegration ? matchingIntegration.mapped_agent_id : ''
            }
            onChange={userMappingChanged}
          >
            <option value={''}>None</option>
            {this.renderIntegrationAgents(integration)}
          </select>
        </td>
      );
    });
  }

  renderIntegrationAgents(integration) {
    if (!integration) {
      return null;
    }

    const { vendor_property_id } = integration;
    const allGroupsIntegrations = this.props.groups.reduce(
      (allGroupIntegrations, group) => [
        ...allGroupIntegrations,
        ...group.integrations,
      ],
      []
    );
    const groupIntegration = _.find(allGroupsIntegrations, {
      vendor_property_id,
    });

    if (!groupIntegration || !groupIntegration.agents) {
      return null;
    }

    return groupIntegration.agents
      .sort((agentA, agentB) => {
        return agentA.agent_name > agentB.agent_name ? 1 : -1;
      })
      .map((agent) => {
        return (
          <option key={agent.agent_id} value={agent.agent_id}>
            {agent.agent_name}
          </option>
        );
      });
  }

  editUser(selectedUser) {
    this.setState({ selectedUser, isAddingUser: false });
  }

  loginToUser(selectedUser) {
    const useCookie = this.useCookieForToken();

    if (useCookie) {
      LoginAPI.openWebappAsUserFromCookie(selectedUser.user_id);
    } else {
      LoginAPI.openWebappAsUser(selectedUser.user_id);
    }
  }

  addUser() {
    this.setState({ selectedUser: null, isAddingUser: true });
  }

  onUpdateTeam(newName) {
    this.props.onUpdateTeam(this.props.group.leasing_team_id, newName);
    this.setState({ editingTeam: false });
  }

  onUserUpdated(user) {
    const integrations = user.integrations.map((integration) => {
      if (
        !integration.mapped_agent_id ||
        integration.mapped_agent_id === 'None'
      ) {
        return {
          ...integration,
          mapped_agent_id: null,
        };
      }

      return integration;
    });
    this.setState({ isSaving: true });
    this.props
      .onUserUpdated({
        ...user,
        integrations,
      })
      .then((wasUpdated) => {
        this.setState({ isSaving: false });
        if (wasUpdated) {
          this.onCancelEdit();
        }
      });
  }

  onUserCreated(user) {
    this.setState({ isSaving: true });
    this.props.onUserCreated(user).then((wasCreated) => {
      this.setState({ isSaving: false });
      if (wasCreated) {
        this.onCancelEdit();
      }
    });
  }

  onUserPasswordUpdated(userId, password) {
    this.setState({ isSaving: true });
    this.props.onUserPasswordUpdated(userId, password).then(() => {
      this.setState({ isSaving: false });
      this.onCancelEdit();
    });
  }

  onCancelEdit() {
    if (this._isMounted) {
      this.setState({ selectedUser: null, isAddingUser: false });
    }
  }

  startDeletingUser(userId, defaultTransferTo) {
    this.setState({
      deletingUserId: userId,
      transferToUserId: defaultTransferTo,
      selectingTransfer: true,
    });
  }

  cancelDeletingUser = () => {
    this.setState({
      deletingUserId: null,
      selectingTransfer: false,
    });
  };

  transferToUserIdChanged = (id) => {
    this.setState({ transferToUserId: id });
  };

  deleteUser = () => {
    this.props.onUserDeleted(
      this.state.deletingUserId,
      this.state.transferToUserId
    );

    this.setState((prevState) => ({
      deletionsInProgress: [
        this.state.deletingUserId,
        ...(prevState.deletionsInProgress || []),
      ],
      deletingUserId: null,
      confirmDeleteGroupUser: false,
      selectingTransfer: false,
    }));
  };

  backToSelect = () => {
    this.setState({
      confirmDeleteGroupUser: false,
      selectingTransfer: true,
    });
  };

  confirmDelete = () => {
    this.setState({
      selectingTransfer: false,
      confirmDeleteGroupUser: true,
    });
  };
}

export default GroupUsers;
